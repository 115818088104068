/* global $ajax, _ */
/* eslint-disable class-methods-use-this */
import { Controller } from 'stimulus';

export default class extends Controller {
  templateColors(showStockDeferred, stockDeferredHoursLabel, showStockHub) {
    return `
      <table class='stock'>
        <thead> <tr>
          <th> Colores </th>
          <th> ${showStockHub ? 'Exist.' : 'Existencia'} </th>
          <th> ${showStockHub ? 'Dispo.' : 'Disponible'} </th>
          ${showStockDeferred ? "<th class='stock-immediate'> 24hs </th>" : ''}
          ${showStockDeferred ? `<th class='stock-deferred'> ${stockDeferredHoursLabel} </th>` : ''}
          ${showStockHub ? "<th class='stock-hub'> A PEDIDO* </th>" : ''}
        </tr> </thead>
        <tbody>
          <% colors.forEach(variant => { %>
            <tr>
              <td>
                <div class='color-container'>
                  <a href="<%= variant.color_url %>", title="<%= variant.color_name %>", class='circle-color <%= variant.product_type%>'>
                    <div class='circle-part primary_color', style="background-image: url(<%= variant.primary_color_url %>)", alt="<%= variant.color_name %>"> </div>
                    <% if (variant.secondary_color_url != undefined) { %>
                      <div class='circle-part secondary_color', style="background-image: url(<%= variant.secondary_color_url %>)", alt="<%= variant.color_name %>"> </div>
                    <% } %>
                    <% if (variant.tertiary_color_url != undefined) { %>
                      <div class='circle-part tertiary_color', style="background-image: url(<%= variant.tertiary_color_url %>)", alt="<%= variant.color_name %>"> </div>
                    <% } %>
                  </a>
                </div>
              </td>
              <td class='stock-existent' > <%= variant.stock_existent %> </td>
              <td class='stock-available' > <%= variant.stock_available %> </td>
              ${showStockDeferred ? "<td class='stock-immediate' > <%= variant.stock_immediate %> </td>" : ''}
              ${showStockDeferred ? "<td class='stock-deferred' > <%= variant.stock_deferred %> </td>" : ''}
              ${showStockHub ? "<td class='stock-hub' > <%= variant.stock_hub %> </td>" : ''}
            </tr>
          <% }) %>
        </tbody>
      </table>
    `;
  }

  templateIcons(showStockHub) {
    return `
      <% icons.forEach(icon => { %>
        <div class='icon'>
          <img src="<%= icon.url %>" title="<%= icon.label %>" alt="<%= icon.label %>" />
        </div>
      <% }) %>

      <% if(${showStockHub}) { %>
        <div class='icon'>
          <img src="/images/a_pedido.png" title="A PEDIDO" alt="A PEDIDO" />
        </div>
      <% } %>
    `;
  }

  loadPrice(rootElement, variant) {
    // old_price_strikethrough
    if (typeof variant.old_price_strikethrough !== 'undefined' && variant.old_price_strikethrough != null) {
      let oldPriceStrikethroughElement = rootElement.querySelector('.old-price-strikethrough');
      if (!oldPriceStrikethroughElement) {
        oldPriceStrikethroughElement = document.createElement('div');
        oldPriceStrikethroughElement.classList.add('old-price-strikethrough');
        rootElement.querySelector('.header').after(oldPriceStrikethroughElement);
      }
      oldPriceStrikethroughElement.innerHTML =
        `${variant.label_old_price_strikethrough}:` +
        `<span class='value'>&nbsp${variant.old_price_strikethrough}&nbsp</span>`;
    }

    // price
    const priceElement = rootElement.querySelector('.price');
    if (priceElement != null) {
      priceElement.innerHTML = `${variant.label_price} <span class="value">&nbsp${variant.price} + IVA`;
      priceElement.classList.remove('skeleton');
    }
  }

  loadIcons(currentVariant, variant) {
    const tmpl = _.template(this.templateIcons(variant.show_stock_hub));
    const printing = currentVariant.querySelector('.icons-list .printing');
    if (printing != null) {
      printing.innerHTML = tmpl({ icons: variant.icons });
    }
  }

  loadColors(currentVariant, variant, stockDeferredHoursLabel) {
    const tmpl = _.template(
      this.templateColors(variant.show_stock_deferred, stockDeferredHoursLabel, variant.show_stock_hub)
    );

    const variantContainer = currentVariant.querySelector('.variant-container .color-variants');

    // container collapsed & show plus
    if (variant.variants_in_site.length > 4 && variantContainer != null) {
      variantContainer.parentElement.classList.add('collapsed');
      const plus = variantContainer.parentElement.querySelector('.expand-variants a.hidden.plus');
      if (plus != null) {
        plus.classList.remove('hidden');
      }
    }

    // show stock-hub-disclaimer
    if (variant.show_stock_hub === true) {
      const stockHubDisclaimer = variantContainer.parentElement.querySelector('.stock-hub-disclaimer.hidden');
      if (stockHubDisclaimer != null) {
        stockHubDisclaimer.classList.remove('hidden');
      }
    }

    const colorsSortedByStockExistent = variant.variants_in_site
      .sort((v1, v2) => {
        return v1.stock_existent - v2.stock_existent;
      })
      .reverse();

    variantContainer.innerHTML = tmpl({ colors: colorsSortedByStockExistent });
  }

  loadVariantsData(data) {
    const rootElement = this.scope.element;

    // Dejo disponible this dentro del forEach

    data.variants.forEach((variant) => {
      const currentVariant = rootElement.querySelector(`#product_variant_${variant.id}`);

      if (typeof currentVariant !== 'undefined' && currentVariant != null) {
        this.loadPrice(currentVariant, variant);
        this.loadIcons(currentVariant, variant);
        this.loadColors(currentVariant, variant, data.stock_deferred_hours_label);
      }
    });
  }

  connect() {
    // load 10 products first
    // first argument limit, second offset
    this.loadProducts(this, 10, 0);
    // load the rest
    this.loadProducts(this, null, 10);
  }

  loadProducts(_this, limit, offset) {
    let url = `${window.location.pathname}.json${window.location.search}`;
    url += url.indexOf('?') >= 0 ? '&' : '?';
    url += `limit=${limit}&offset=${offset}`;

    $ajax.send(
      url,
      {
        onSuccess(data, __this) {
          __this.loadVariantsData(data);
        },
        timeout: 10000,
      },
      _this
    );
  }
}
